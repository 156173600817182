import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showSpinner: false,
    isMobile: false,
    requestedEndPoints: [],
    selectedLocation: null,
  },
  getters: {
    getSpinner(state) {
      return state.showSpinner;
    },
    isMobile(state) {
      return state.isMobile;
    },
    getSelectedLocation(state) {
      return state.selectedLocation;
    },
  },
  mutations: {
    setSelectedLocation(state, value) {
      state.selectedLocation = value;
    },
    setMobile(state, value) {
      state.isMobile = value;
    },
    setSpinner(state, value) {
      state.showSpinner = value;
    },
    pushRequestedEndPoints(state, payload) {
      state.requestedEndPoints.push(payload);
    },
    popRequestedEndPoints(state, payload) {
      state.requestedEndPoints = state.requestedEndPoints.filter(
        (e) => e !== payload
      );
      if (state.requestedEndPoints.length === 0) {
        state.showSpinner = false;
      }
    },
  },
  actions: {
    setMobile({ commit }, value) {
      commit("setMobile", value);
    },
    setSpinner({ commit }, value) {
      commit("setSpinner", value);
    },
    pushRequestedEndPoints({ commit }, value) {
      commit("pushRequestedEndPoints", value);
    },
  },
  modules: {},
});
