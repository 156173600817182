<template>
  <v-container fluid>
    <v-card class="pt-10 primary--text" color="transparent" flat>
      <v-row>
        <v-col cols="12">
          <v-row no-gutters justify="center" class="footer text-center">
            <v-col
              cols="12"
              lg="3"
              md="3"
              class="text-left mb-8 mb-lg-0 mb-md-0"
            >
              <div>
                <router-link
                  :to="{ name: 'HomeView' }"
                  class="primary--text text-center text-lg-left text-md-left"
                  style="text-decoration: none"
                >
                  <h2>Happy Harbour</h2>
                </router-link>
              </div>
              <div
                class="social-icons text-center text-lg-left text-md-left"
                @click="goSocial"
              >
                <strong class="primary--text cursor-pointer"
                  >Follow Us On</strong
                >
                <v-btn icon large class="mr-lg-4 mr-md-4 cursor-pointer">
                  <v-icon color="primary">mdi-instagram</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="5"
              md="5"
              sm="6"
              class="text-left mb-8 mb-lg-0 mb-md-0"
            >
              <div class="mb-4 mb-lg-2 mb-md-2 primary--text">
                <b>Quick Links</b>
              </div>
              <v-row>
                <v-col cols="5" lg="5" md="5">
                  <div class="footer-links">
                    <div>
                      <router-link
                        exact
                        :to="{ name: 'HomeView', hash: '#about-us' }"
                        class="primary--text"
                        style="text-decoration: none"
                        >About Us
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        exact
                        :to="{ name: 'HomeView', hash: '#our-space' }"
                        class="primary--text"
                        style="text-decoration: none"
                        >Our Space
                      </router-link>
                    </div>
                    <!-- <div>
                                            <router-link exact :to="{ name: 'HomeView', hash: '#testimonial' }"
                                                class="primary--text" style="text-decoration: none">Testimonial
                                            </router-link>
                                        </div> -->
                    <div>
                      <router-link
                        exact
                        :to="{ name: 'HomeView', hash: '#contact-us' }"
                        class="primary--text"
                        style="text-decoration: none"
                        >Contact us
                      </router-link>
                    </div>
                  </div>
                </v-col>
                <v-col cols="7" lg="5" md="5">
                  <div class="footer-links">
                    <div>
                      <router-link
                        class="primary--text"
                        style="text-decoration: none"
                        :to="{ name: 'TermsAndConditionsView' }"
                      >
                        Terms & Conditions
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        class="primary--text"
                        style="text-decoration: none"
                        :to="{ name: 'RefunfPolicyView' }"
                      >
                        Refund Policy
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        class="primary--text"
                        style="text-decoration: none"
                        :to="{ name: 'PrivacyPolicyView' }"
                      >
                        Privacy Policy
                      </router-link>
                    </div>
                    <!-- <div>
                                            <router-link class="primary--text" style="text-decoration: none"
                                                :to="{ name: 'AdminView' }">
                                                Admin Login</router-link>
                                        </div> -->
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="6"
              class="text-left mb-8 mb-lg-0 mb-md-0 primary--text"
            >
              <div class="mb-2">
                <b>Address</b>
              </div>
              <div>Happy Harbour</div>

              <div>
                4th Floor, Saphire, Opp RNSIT Kengeri main Road, Uttarahalli
                Main Rd, Channasandra, Bengaluru, Karnataka 560098  
              </div>
              <br />
              <div class="text-left">
                <b>Phone No:</b>
                <a
                  class="primary--text"
                  style="text-decoration: none"
                  href="tel:+9686411733"
                >
                  9686-411-733
                </a>
              </div>
              <div class="text-left">
                <b>Mail us: </b>
                <a
                  class="primary--text"
                  style="text-decoration: none"
                  href="mailto:happyharbourofficial@gmail.com"
                  >happyharbourofficial@gmail.com</a
                >
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="pt-0 pt-lg-3 pt-md-3">
                Copyright ©2024 All Rights Reserved | Happy Harbour
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AppFooter",
  components: {},
  methods: {
    goSocial() {
      window.open("https://www.instagram.com/happyharbour.in");
    },
  },
};
</script>
<style scoped>
.footer-links > div {
  margin-bottom: 8px;
}
</style>
